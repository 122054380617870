<template>
    <a-modal v-model="modalVisible" title="商品发布" @cancel="OnClose">
        <template v-for="Product in ProductType">
            <a-button :key="Product.ID" @click="selecttype(Product.ID)" style="margin-right:10px;">
                {{ Product.Name }}
            </a-button>
        </template>
        <div slot="footer"></div>
    </a-modal>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import Vue from 'vue'
    export default {
        name: "Product_Add_Dialog",
        data() {
            return {
                modalVisible: false,
                ProductType: []
            };
        },
        props: {//组件属性
            Show: Boolean,
            M2ActivityID: Number,
            M2ActivityProductID: Number,
            ReturnUrl: String
        },
        methods: {//方法
            selecttype(e) {
                var self = this;
                self.$router.push({
                    name: "ProductDetail",
                    query: { id: 0, commission: 0, disabled: 0, ProductType: e, M2ActivityID: self.M2ActivityID, M2ActivityProductID: self.M2ActivityProductID, ReturnUrl: self.ReturnUrl }
                });
            },
            OnClose() {
                this.$emit("hide");
            }
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
            Show: function (val) {
                var self = this;
                if (val) {
                    var op2 = {
                        url: "/ShopModule/Product/GetStoreType",
                        data: {
                        },
                        OnSuccess: function (data) {
                            if (data.data.length > 1) {
                                self.modalVisible = true;
                                self.ProductType = data.data;
                            } else {
                                self.$emit("hide", false);
                                Vue.global.VueRoot.$router.push({
                                    name: "ProductDetail",
                                    query: { id: 0, commission: 0, disabled: 0, ProductType: data.data[0].ID, M2ActivityID: self.M2ActivityID, M2ActivityProductID: self.M2ActivityProductID, ReturnUrl: self.ReturnUrl }
                                });
                            }
                        }
                    };
                    http.Post(op2);
                }
                else {
                    self.modalVisible = false;
                }

            }
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>
<template>
    <div>
        <a-layout>
            <div class="yj-conten" style="background-color:#fff;width:calc(100% - 32px); min-width:fit-content">
            
                <a-layout-content style="padding:0;">
                    <div class="yj-conten " style="background: #fff;padding: 20px 20px 0;margin: 0;">
                        <a-row class="fm" :gutter="10"  type='flex' justify="space-between">
                           
                            <a-col   :span='12' > 
                              <label>商品：</label>
                                <a-input id="name"
                                         contextmenu="2455"
                                         :value="name"
                                         style="width:260px"
                                         @change="name_change"
                                         placeholder="查找商品"></a-input><a-button style="margin-left:10px" type="primary" ghost @click="search">查询</a-button>
                                       <a-radio-group :default-value="Online"   style="margin-left:10px" @change="radioChange">
                                    <a-radio-button value="-1">全部</a-radio-button>
                                    <a-radio-button value="1">销售中</a-radio-button>
                                    <a-radio-button value="0">已下架</a-radio-button>
                                </a-radio-group>
                                         </a-col>  
                                
                            <a-col :span="12" style="text-align:right" >
                                    <a-button type="primary" style="margin-left:10px" @click="AddSpecification()">新增商品规格</a-button>
                                    <a-button type="primary" style="margin-left:10px" @click="addRow()">新增商品</a-button>
                               


                               
                            </a-col>
                    
                        </a-row>
                        <a-table :columns="columns" bordered
                                 rowKey="ID"
                                 :data-source="tableData"
                                 :pagination="pagination"
                                 @change="pagerChange"
                                 style=" min-height: calc(100vh - 234px);min-width: max-content"
                                 class="yj-table-img">
                            <span slot="action" slot-scope="text, record">
                                <a v-if="record.Online==1" @click="editRow(record,1,1)">佣金详情</a>
                                <a-divider type="vertical" v-if="record.Online==1" />
                                <a v-if="record.Online==1" @click="editRow(record,0,1)">商品详情</a>
                                <a-divider type="vertical" v-if="record.Online==1" />
                                <a v-if="record.Online==0" @click="editRow(record,1,0)">佣金</a>
                                <a-divider type="vertical" v-if="record.Online==0" />
                                <a v-if="record.Online==0" @click="editRow(record,0,0)">编辑</a>
                                <a-divider type="vertical" v-if="record.Online==0" />
                                <a @click="UpdateOnline(record)">{{ record.Online==1?'下架':'上架' }}</a>
                                <a-divider v-if="record.Online==0" type="vertical" />
                                <a v-if="record.Online==0" @click="delRow(record)" style=" color:red">删除</a>
                            </span>

                            <span slot="Online" slot-scope="text, record">
                                <span v-if="ShowEnum(record.Online)=='已下架'">{{ShowEnum(record.Online)}}</span>
                                <span v-else style="color:#52ca5c">
                                    {{ShowEnum(record.Online)}}
                                </span>
                            </span>
                        </a-table>
                    </div>
                </a-layout-content>
            </div>
        </a-layout>
        <Product_Add_Dialog v-bind:Show="modalVisible" v-on:hide="modalVisible=false" v-bind:M2ActivityID="M2ActivityID" v-bind:M2ActivityProductID="M2ActivityProductID" v-bind:ReturnUrl="ReturnUrl"></Product_Add_Dialog>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    import Product_Add_Dialog from "@/Views/ShopModule/Product/Add.vue";
    export default {
        name: "ProductList",
        data() {
            return {
                columns: [

                    {
                        title: "商品名称",
                        width: 360,
                        customRender: (text, item) => {
                            var src = '#';
                            var label = item.Name;
                            var intro = item.Summary
                            if (item.ProductExImage.length > 0) {
                                src = item.ProductExImage[0].ImagePath;
                            }
                            return <div><img src={src} width="50px" height="50px" style="float: left;margin-right:16px;" /><strong class='goods-name'>{label}</strong><p class='intoduce' style="">{intro}</p></div>;
                        }
                    },

                    {
                        title: "价格",
                        dataIndex: "SaleMoney",
                        align: "center",
                        width: 200,
                        customRender: (text, item) => {
                            return util.ToFixed(item.SaleMoney);
                        }
                    },
                    {
                        title: "预付金额",
                        dataIndex: "AdvanceMoney",
                        align: "center",
                        width: 160,
                        customRender: (text, item) => {
                            var rt = util.ToFixed(item.AdvanceMoney);
                            if (item.BuyMoneyType == "0") {
                                rt = util.ToFixed(0);
                            }
                            return rt;
                        }
                    },
                    {
                        title: "销量",
                        dataIndex: "BuyCount",
                        align: "center",
                        width: 120
                    },
                    {
                        title: "状态",
                        width: 200,
                        align: "center",
                        scopedSlots: { customRender: 'Online' }
                    },

                    {
                        title: '操作',
                        align: "center",
                        scopedSlots: { customRender: 'action' },
                    }
                ],
                Online: "-1",
                tableData: [],
                name: "",
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                jsondata: [],
                modalVisible: false,
                M2ActivityID: 0,
                M2ActivityProductID: 0,
                ReturnUrl: 'ProductList'
            };
        },
        methods: {
            name_change: function (item) {
                var self = this;
                self.name = item.target.value;
            },
            radioChange: function (item) {
                var self = this;
                self.Online = item.target.value;
                self.pagination.current = 1;
                self.getTableData();
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getTableData();
            },
            search: function () {
                var self = this;
                self.pagination.current = 1;
                self.getTableData();
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/ShopModule/Product/ProductList",
                    data: {
                        pageIndex: self.pagination.current,
                        Online: self.Online,
                        name: self.name
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
                var op2 = {
                    url: "/ShopModule/Product/SetDuplicate",
                    data: {
                        key: self.name
                    },
                    OnSuccess: function (data) {
                        if (JSON.stringify(data.data) !== '{}') {
                            alert('总数:' + data.data.Counts + '\n导入:' + data.data.Success);
                        }
                    }
                };
                http.Post(op2);
            },
            addRow() {
                this.modalVisible = true;
            },
            AddSpecification(){
                //跳转新增商品规格
                this.$router.push({
                    name:"SpecificationList",//路由名称
                });
            },
            editRow(e, b, c) {
                var self = this;
                self.$router.push({
                    name: "ProductDetail",
                    query: { id: e.ID, commission: b, disabled: c, ProductType: e.ProductType, M2ActivityID: 0, M2ActivityProductID: 0, ReturnUrl: self.ReturnUrl }
                });
            },
            delRow(e) {
                var self = this;
                this.$confirm({
                    title: '提示',
                    content: '是否删除' + e.Name,
                    okText: "确定",
                    cancelText: '取消',
                    onOk() {
                        var op = {
                            url: "/ShopModule/Product/DeleteProduct",
                            data: {
                                id: e.ID
                            },
                            OnSuccess: function () {
                                self.pagination.current = 1;
                                self.getTableData();
                                self.Tipsconfirm('删除' + e.Name);
                            }
                        };
                        http.Post(op);
                    },
                    onCancel() { },
                });
            },
            UpdateOnline(e) {
                var self = this;
                var con = e.Online == 1 ? '下架' : '上架';
                var status = e.Online == 1 ? 0 : 1;
                this.$confirm({
                    title: '提示',
                    content: '是否' + con + e.Name,
                    okText: "确定",
                    cancelText: '取消',
                    onOk() {
                        var op = {
                            url: "/ShopModule/Product/UpdateOnline",
                            data: {
                                id: e.ID,
                                Online: status
                            },
                            OnSuccess: function () {
                                self.pagination.current = 1;
                                self.getTableData();
                                self.Tipsconfirm(con + e.Name);
                            }
                        };
                        http.Post(op);
                    },
                    onCancel() { },
                });
            },
            Tipsconfirm(e) {
                this.$info({
                    title: '提示',
                    content: '成功' + e,
                    okText: "确定"
                });
            },
            getData2: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.ProductModule",//程序集名称
                        TypeName: "Welcome.ProductModule.Enumerate.EnumOnline"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.jsondata = response.data
                    }
                }
                http.Post(op);
            },
            ShowEnum: function (Key) {//枚举不是从0开始，或不连续，可以使用此方法
                var Self = this;
                var item = Self.jsondata.find(function (item) {
                    return item.ID == Key;
                });
                if (item)
                    return item.Name
                else
                    return "";
            }
        },
        mounted() {
            this.getData2();
            this.getTableData();
        },
        computed: {},
        components: {
            Product_Add_Dialog
        }
    };
</script>
<style scoped>
    .intoduce {
        max-width: 240px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-align: left !important;
        margin: 0;
        font-size: 12px;
        white-space: normal;
    }
.fm{ padding-bottom: 20px;}
    .goods-name {
        font-size: 16px;
    }
</style>